export type Metadata =
  {
    rawMetadata?: string;
    metadataUrl?: string;
  };

export interface IdpMetadataGetResponse {
  idpMetadataInfoList: IdpMetadataInfo[];
}

export interface IdpMetadataInfo {
  idpMetadataId: string;
  idpId: string;
  idpDisplayName: string;
  metadataUrl?: string;
}

export interface IdpMetadataSaveRequest {
  displayName: string;
  metadata: Metadata;
}

export interface IdpMetadataUpdateRequest extends IdpMetadataSaveRequest {
  metadataId: string;
}

export interface IdpConfigGetResponse {
  idpConfigId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}
export interface IdpConfigGetRequest {
  idpMetadataId: string;
}

export interface IdpConfigSaveRequest {
  idpMetadataId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}

export interface IdpConfigSaveResponse {
  idpConfigId: string;
}

export interface IdpConfigUpdateRequest {
  idpConfigId: string;
  samlGroupAttrName: string;
  samlGroupAttrValues: string[];
}

export interface IdpConfigUpdateResponse {
  idpConfigId: string;
}

export interface OrgPreferencesGetResponse extends OrgPreferencesUpdateRequest {}

export interface OrgPreferencesUpdateRequest {
  logoUrl?: string;
  orgName?: string;
  usernameLabel?: string;
  passwordLabel?: string;
  proxCardLabel?: string;
}

export interface DomainsGetResponse {
  names: string[];
}

export interface DomainsSetRequest {
  names: string[];
}

export interface GetAadConsentRequest {
  aadTenantId: string;
}

export interface GetAadConsentResponse {
  aadConsentUrl: string;
}

export interface TenantSetupCompleteRequest {
  email: string;
  withEntraId: boolean;
  consent?: ApiConsent;
}

export interface ApiConsent {
  grantType: string;
  documentName: string;
}

export interface TenantLookupResponse {
  tenantIds: string[];
}

export interface GetClientUserSessionResponse {
  tenantId?: string;
  userInfo?: UserInfo;
}

export type UserInfo = {
  username: string;
  email: string;
  roleType: string;
  productExperienceVisitorId?: string;
};

export interface EntraIdConsentUrlGetResponse {
  entraConsentUrl: string;
}

export interface EntraIdConsentUrlGetRequest {
  entraTenantId: string;
}

export interface EntraIdTenantSetupResponse {
  entraUserId: string;
}

export type OrgInfo = {
  name: string;
  logoUrl: string;
};

export type StartSsoLink = {
  startSsoUrl: string;
  applianceDesignator: string;
};

export type PurchasedProduct = {
  displayName: string;
  productTag: string;
  startSsoLinks: StartSsoLink[];
};

export type NonPurchasedProduct = {
  displayName: string;
  productTag: string;
  learnMoreUrl: string;
};

export type ProductInfo = {
  purchasedProducts: PurchasedProduct[];
  nonPurchasedProducts: NonPurchasedProduct[];
};

export interface GetLandingPageResponse {
  orgInfo?: OrgInfo;
  productInfo: ProductInfo;
}

export enum FileAccessModifier {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export type PrepareFileUploadResponse = {
  uploadUrl: string;
  readUrl: string;
};

// DPA
export interface CheckDpaConsentRequest {
  consentDocumentName: string;
}
export interface CheckDpaConsentResponse {
  actionType: CheckDpaConsentResponseActionType;
}
export enum CheckDpaConsentResponseActionType {
  NONE = 'NONE', // Don't show the clickthrough
  CHECK_NEEDED = 'CHECK_NEEDED',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
}

// create token
export interface ClientCreateRequest {
  enterpriseId: string;
  installationTokenExpirationInMinutes: number;
}

export interface ClientCreateResponse {
  clientMetadata: ClientMetadata;
}

export type ClientMetadata = {
  clientId: string;
  eamEnterpriseId: string;
  installationToken: TokenSecret;
  tokenExpiryTime: number;
  createdAt: number;
  installationTokenValue: string;
};
export type TokenSecret = {
  data: string;
  origin: null;
  isProxied: false;
};

export type GroupRequest = {
  groupName: string;
  groupId: string;
  userCount: number;
  syncEnabled: boolean;
  adminEnabled: boolean;
  idpDeleted: boolean;
};

export type FindDirectoriesResponse = {
  directories: Directory[];
};

export type Directory = {
  name: string;
  directoryId: string;
  latestSyncAt: string;
  syncedGroupCount: number;
  idpTenantId: string;
};

export type FindUsersResponse = {
  users: User[];
  totalCount: number;
  latestUpdatedAt: string;
  pagingInfo?: PagingInfo;
};

export type User = {
  userId: string;
  displayName: string;
  upn: string;
};

export type PagingInfo = {
  afterToken: string;
  beforeToken: string;
};
