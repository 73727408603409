import { Button } from '@imprivata-cloud/components';
import { useQuery } from '@tanstack/react-query';
import { Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../api/portalServices';
import SamlIcon from '../../../assets/saml.svg?react';
import IdpInfoForm, { type FormValues } from '../../../components/idpInfoForm/IdpInfoForm';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import type { AppError } from '../../../errorHandler/errors';
import { withRole } from '../../authorization/Roles';
import { useSession } from '../../authorization/context/Session';
import { ROLE_TYPE_ADMIN } from '../../constants';

const Directories = () => {
  const { t } = useTranslation();
  const [addSamlModalVisible, setAddSamlModalVisible] = useState(false);
  const [samlConfigForm] = Form.useForm<FormValues>();
  const { emitError } = useNotifications();
  const [initialSamlConfig, setInitialSamlConfig] = useState<{ name: string; values: string[] } | null>(null);
  const { tenantId } = useSession();

  // Get IdP Metadata info (idpMetadataId)
  const { data: idpMetadataResponse, error: idpError } = useQuery({
    queryKey: ['idpMetadata'],
    queryFn: api.idpGet,
    enabled: addSamlModalVisible,
    retry: false,
  });

  useEffect(() => {
    if (idpError) {
      emitError(idpError as AppError);
    }
  }, [idpError, emitError]);

  const idpMetadataId = idpMetadataResponse?.idpMetadataInfoList?.[0]?.idpMetadataId ?? '';

  // Get Admin Groups for SAML config
  const { data: samlConfigData, error } = useQuery({
    queryKey: ['idpConfigSAML', idpMetadataId],
    queryFn: async () => api.getIdpConfigSAML({ idpMetadataId }, tenantId || ''),
    enabled: addSamlModalVisible && Boolean(idpMetadataId),
    retry: false,
  });
  const idpConfigId = samlConfigData?.idpConfigId;

  useEffect(() => {
    if (error) {
      emitError(error as AppError);
    }
  }, [error, emitError]);

  useEffect(() => {
    if (samlConfigData) {
      setInitialSamlConfig({
        name: samlConfigData.samlGroupAttrName,
        values: samlConfigData.samlGroupAttrValues,
      });
      samlConfigForm.setFields([
        { name: 'AdminGroupsName', value: samlConfigData.samlGroupAttrName },
        { name: 'AdminGroupsValues', value: samlConfigData.samlGroupAttrValues.join(', ') },
      ]);
    }
  }, [samlConfigData, samlConfigForm]);

  // Check if there are changes in the SAML groups
  const hasChanges = () => {
    const formValues = samlConfigForm.getFieldsValue();
    return (
      formValues.AdminGroupsName !== initialSamlConfig?.name ||
      formValues.AdminGroupsValues !== initialSamlConfig?.values.join(', ')
    );
  };

  // Update SAML config
  const handleSamlGroupsConfigUpdate = async (values: FormValues) => {
    try {
      const response = await api.updateIdpConfigSAML(
        {
          idpConfigId: idpConfigId || '',
          samlGroupAttrName: values.AdminGroupsName || '',
          samlGroupAttrValues: (values.AdminGroupsValues ?? '').split(',').map((value: string) => value.trim()),
        },
        tenantId || '',
      );
      console.log('Config updated:', response);
    } catch (error) {
      emitError(error as AppError);
    }
  };

  const submitSamlConfig = async () => {
    const values = samlConfigForm.getFieldsValue() as FormValues;
    console.log(values);
    // TODO add BFF interactions and validations

    // Interactions and Validations: SAML GROUPS
    hasChanges() ? handleSamlGroupsConfigUpdate(values) : console.log('No changes, skipping groups update');
  };

  const closeIdpModal = () => {
    samlConfigForm.resetFields();
    setAddSamlModalVisible(false);
  };

  return (
    <>
      <div style={{ backgroundColor: 'white' }}>
        <Button type="text" icon={<SamlIcon />} onClick={() => setAddSamlModalVisible(true)}>
          {t('portal.settings.directories.add-saml-button')}
        </Button>
      </div>
      {/* Add SAML config modal */}
      <Modal
        styles={{ header: { backgroundColor: '#F4F5F7' } }}
        centered
        title={<div className="modal-header-content h3">{t('portal.settings.directories.add-saml-modal-header')}</div>}
        open={addSamlModalVisible}
        width={825}
        destroyOnClose
        footer={null}
        onCancel={closeIdpModal}
      >
        <IdpInfoForm
          form={samlConfigForm}
          isModalView={true}
          withGroups={true}
          modalOnClose={closeIdpModal}
          onFinish={submitSamlConfig}
        />
      </Modal>
    </>
  );
};

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Directories);
