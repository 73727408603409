import { Button } from '@imprivata-cloud/components';
import { Col, Form, Modal, Row } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { idpGet, idpUpdate } from '../../../api/portalServices';
import type { IdpMetadataInfo } from '../../../api/types';
import EditIcon from '../../../assets/icons/edit.svg?react';
import { IntegrationTokenPage } from '../../../common/integration-token/IntegrationTokenPage';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { type ApiError, AppError } from '../../../errorHandler/errors';
import './Integrations.less';
import IdpInfoForm, { type FormValues, IdpFormFieldNames } from '../../../components/idpInfoForm/IdpInfoForm';
import { withRole } from '../../authorization/Roles';
import { ROLE_TYPE_ADMIN } from '../../constants';

const Integrations = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { emitError, clearErrorNotifications } = useNotifications();
  const [idpMetadataInfoList, setIdpMetadataInfoList] = useState<IdpMetadataInfo[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idpForm] = Form.useForm<FormValues>();

  const fetchData = useCallback(async () => {
    try {
      clearErrorNotifications();
      setIsLoading(true);
      const idp = await idpGet();
      setIdpMetadataInfoList(idp.idpMetadataInfoList);
      const metadata = idp?.idpMetadataInfoList?.[0];
      if (!metadata) {
        emitError(AppError.fromMessage('Invalid state exception: metadata cannot be empty.'));
        return;
      }
      idpForm.setFieldValue(IdpFormFieldNames.NAME, metadata.idpDisplayName);
      idpForm.setFieldValue(IdpFormFieldNames.URL, metadata.metadataUrl);
      setIsLoading(false);
    } catch (e) {
      emitError(new AppError(e as ApiError));
    }
  }, [idpForm.setFieldValue, emitError, clearErrorNotifications]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  async function idpFormOnFinish(formValues: FormValues) {
    const idpMetadataInfo = idpMetadataInfoList?.[0];
    try {
      setIsLoading(true);
      clearErrorNotifications();
      await idpUpdate({
        metadataId: idpMetadataInfo?.idpMetadataId || '',
        displayName: formValues.IdPName,
        metadata: {
          metadataUrl: idpMetadataInfo?.metadataUrl || undefined,
          rawMetadata: formValues.IdPXML || undefined,
        },
      });
      await fetchData();
      closeModal();
    } catch (error) {
      console.log('Failed call idpFormOnFinish', error);
      if (error instanceof AppError) {
        emitError(error);
      } else {
        emitError(AppError.fromMessage('Save failed'));
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="integrations-container">
      <Row>
        <Col sm={24} md={24} lg={24} className="setup-content p-xxxl">
          <h2>{t('portal.settings.integrations.header')}</h2>
          <div className="idp-row">
            <div>{idpMetadataInfoList?.[0]?.idpDisplayName}</div>
            <Button disabled={isLoading} type="text" icon={<EditIcon />} onClick={() => setIsModalVisible(true)} />
          </div>
        </Col>
      </Row>
      <Modal
        styles={{ header: { backgroundColor: 'var(--impr-blue-5)' } }}
        centered
        title={<div className="modal-header-content h3">{t('portal.settings.integrations.modal.title')}</div>}
        open={isModalVisible}
        width={825}
        destroyOnClose
        footer={null}
        onCancel={closeModal}
      >
        <IdpInfoForm
          form={idpForm}
          isModalView={true}
          isEditMode={true}
          withGroups={true}
          modalOnClose={closeModal}
          onFinish={idpFormOnFinish}
        />
      </Modal>
      <div className="divider" />
      <Row className="">
        <Col sm={24} md={24} lg={24} className="setup-content p-xxxl">
          <h2>{t('common.integration-token.page-title')}</h2>
          <IntegrationTokenPage isSetup={false} />
        </Col>
      </Row>
    </div>
  );
};

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Integrations);
