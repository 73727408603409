import { type AntListItem, ImprList } from '@imprivata-cloud/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { findDirectories, findUsers } from '../../../api/portalServices';
import type { Directory } from '../../../api/types';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import type { AppError } from '../../../errorHandler/errors';
import { useSession } from '../../authorization/context/Session';
import UsersEmptyPage from './UsersEmptyPage';

const Users = () => {
  const { t } = useTranslation();
  const { tenantId } = useSession();
  const [directories, setDirectories] = useState<Directory[]>();
  const [users, setUsers] = useState<AntListItem[]>();
  const { emitError } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDirectories = async () => {
      return await findDirectories(tenantId ?? '');
    };
    const loadUsers = async () => {
      return await findUsers(tenantId ?? '');
    };
    try {
      loadDirectories().then((response) => {
        const directories = response?.directories;
        console.log('response:', response);
        setDirectories(directories);
        if (directories && directories[0]?.syncedGroupCount > 0) {
          loadUsers().then((userRes) => {
            const userList = userRes?.users.map((user) => {
              const userItem: AntListItem = {
                itemTitle: user.displayName,
                referenceKey: user.upn,
              };
              return userItem;
            });
            setUsers(userList);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      });
    } catch (e) {
      console.error('Error fetching directories/users:', e);
      emitError(e as AppError);
      setIsLoading(false);
    }
  }, [tenantId, emitError]);

  const sortProps = {
    enableSort: false,
    sortLabel: 'All users (by name)',
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div>
      <ImprList
        items={users || []}
        customEmptyListNode={<UsersEmptyPage directories={directories} />}
        sortProps={sortProps}
        searchEnabled={false}
      />
    </div>
  );
};

export default Users;
