import { Button, DialogVariant, ImprDialog } from '@imprivata-cloud/components';
import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntraIdConsentUrl } from '../../../api/portalServices';
import { ErrorCode } from '../../../errorHandler/constants';
import { useNotifications } from '../../../errorHandler/context/Notifications';
import { AppError } from '../../../errorHandler/errors';
import { useSession } from '../../authorization/context/Session';
import ConfigEntraID from '../config-entraID/configEntraID';
import './UsersEmptyPage.less';
import type { Directory } from '../../../api/types';

const TRANSLATION_ROOT = 'portal.config-entra-id';

export interface UsersEmptyPageProps {
  directories?: Directory[];
}

const UsersEmptyPage = ({ directories }: UsersEmptyPageProps) => {
  const { t } = useTranslation();
  const { emitError } = useNotifications();
  const [addEntraIdVisible, setAddEntraIdVisible] = useState(false);
  const [entraIdForm] = Form.useForm();
  const entraIdValue = useWatch('entraId', { form: entraIdForm });
  const isEntraIdValid = Boolean(entraIdValue);
  const { tenantId } = useSession();

  const redirectToMicrosoft = async () => {
    try {
      const entraId = entraIdForm.getFieldValue('entraId');
      const consentUrlData = await getEntraIdConsentUrl({ entraTenantId: entraId }, tenantId ?? '');
      if (consentUrlData?.entraConsentUrl) {
        window.location.href = consentUrlData?.entraConsentUrl;
      } else {
        console.error('Error fetching Entra Id consent url:', consentUrlData);
        emitError(new AppError({ code: ErrorCode.UNKNOWN, message: 'Error fetching Entra Id consent url' }));
      }
    } catch (error) {
      console.error('Error fetching Entra Id consent url:', error);
      emitError(error as AppError);
    }
  };

  return (
    <>
      <div className={'add-entra-id-container'}>
        <p>No users</p>
        {(!directories || !directories[0]) && (
          <div>
            <Button type={'link'} onClick={() => setAddEntraIdVisible(true)}>
              Add an Entra ID directory now
            </Button>
          </div>
        )}
        {directories && directories[0]?.syncedGroupCount === 0 && (
          <div>
            <Button type={'link'} onClick={() => alert('Manage Groups')}>
              Specify groups now
            </Button>
          </div>
        )}
      </div>
      {/* Entra ID Modal */}
      <ImprDialog
        open={addEntraIdVisible}
        type={DialogVariant.HEADER_DUAL_BUTTON}
        width={710}
        submitButtonProps={{
          label: t(`${TRANSLATION_ROOT}.continue-to-microsoft`),
          disabled: !isEntraIdValid,
          onClick: redirectToMicrosoft,
        }}
        cancelButtonProps={{ label: t(`${TRANSLATION_ROOT}.cancel`), onClick: () => setAddEntraIdVisible(false) }}
        title={t(`${TRANSLATION_ROOT}.title`)}
        content={<ConfigEntraID tenantId={tenantId ?? ''} entraIdForm={entraIdForm} />}
        onCancel={() => setAddEntraIdVisible(false)}
      />
    </>
  );
};

export default UsersEmptyPage;
