// Copyright 2025, Imprivata, Inc.  All rights reserved.

import { Button, ImprList, ImprTooltip } from '@imprivata-cloud/components';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as api from '../../../api/portalServices';
import AddImg from '../../../assets/add_user.svg?react';
import DeleteImg from '../../../assets/delete.svg?react';
import { withRole } from '../../authorization/Roles';
import { ROLE_TYPE_ADMIN } from '../../constants';

const TRANSLATION_ROOT = 'portal.settings.groups';

const Groups = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const tenantId = location.state?.tenantId;

  // Fetch groups
  const {
    data: groups = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['groups', tenantId],
    queryFn: () => api.getGroups(tenantId),
  });

  if (isLoading) console.log('Loading...');
  if (isError) console.log('Error...', isError);

  // Header Extra Components
  const AddIdGroupsButton = (
    <Button
      type="text"
      label={t(`${TRANSLATION_ROOT}.entra-id-groups`)}
      icon={<AddImg />}
      onClick={() => console.log('ADD entra ID groups')}
    />
  );

  const AddAdminGroupsButton = (
    <Button
      type="text"
      label={t(`${TRANSLATION_ROOT}.admin-groups`)}
      icon={<AddImg />}
      onClick={() => console.log('add administrator groups')}
    />
  );

  // Row actions
  const DeleteRowButton = (
    <ImprTooltip title={t(`${TRANSLATION_ROOT}.remove`)}>
      <Button type="text" icon={<DeleteImg />} onClick={() => console.log('Remove group:')} />
    </ImprTooltip>
  );

  // Map groups to ImprList items
  const items = groups.map((group) => ({
    itemTitle: group.groupName,
    referenceKey: group.groupId,
  }));

  return (
    <div className="groups-container">
      <ImprList
        items={items}
        headerExtraComponents={[AddIdGroupsButton, AddAdminGroupsButton]}
        rowActions={[DeleteRowButton]}
      />
    </div>
  );
};

export default withRole({ authorizedRoles: [ROLE_TYPE_ADMIN] })(Groups);
